
  
  .ptext {
    font-size: 200%;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    color: #1be1c7;
  }
  .ptext2 {
    font-size: 200%;
    color: #1be1c7;
    padding-top: 14%;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
  }
  .ptext3 {
    font-size: 150%;
    padding: 2%;
    color: #1be1c7;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    
  }
  
  .fancy {
    @supports (background-clip: text) or (-webkit-background-clip: text) {
      background-image: 
        url("data:image/svg+xml,%3Csvg width='2000' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='307' r='166' fill='%2300FDCF'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2300FDCF'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: 110% auto;
      background-position: center;
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      color: #1be1c7;
      
      
    }
  }