
  .leaderboard-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #111111D0;
    z-index: 900;
  }
 
 
  
  main {
    width: 80vw;
    
    background-color: #3c3c3c;
    -webkit-box-shadow: 0px 2px 6px 3px #3c3c3c;
    box-shadow: 0px 2px 6px 3px #3c3c3c;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
  }
  
  #header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 2rem;
  }
  
  .share {
    width: 4.5rem;
    height: 3rem;
    background-color: #f55e77;
    border: 0;
    border-bottom: 0.2rem solid #c0506a;
    border-radius: 2rem;
    cursor: pointer;
  }
  
  .share:active {
    border-bottom: 0;
  }
  
  .share i {
    color: #fff;
    font-size: 2rem;
  }
  

  
  #leaderboard {
    width: 100%;
    position: relative;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    color: #04FDCF;
    cursor: default;
  }
  
  tr {
    transition: all 0.2s ease-in-out;
    border-radius: 0.2rem;
   
    padding: clamp(0.5vw, 0.5rem, 1rem); /* Adjust row padding dynamically */
      
  }
  
  tr:not(:first-child):hover {
    background-color: #1e1e1e;
    transform: scale(1.1);
    -webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
    box-shadow: 0px 5px 15px 8px #e4e7fb;
  }
  
  tr:nth-child(odd) {
    background-color: #1e1e1e;
  }
  
  tr:nth-child(1) {
    color: black;
  }
  
  td {
    height: 5rem;
    font-family: "Rubik", sans-serif;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    position: relative;
  }
  
  .number {
    width: 10%;
    font-size: clamp(1.2vw, 1.6rem, 2.2rem);
    font-weight: bold;
    text-align: left;
  }
  
  .name {
    width: 60%;
    text-align: left;
    overflow: hidden;
  text-overflow: ellipsis;
    font-size: 90%;
  }
  
  .points {
    
    font-weight: bold;
    font-size: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .points:first-child {
    width: 10rem;
  }
  
  .gold-medal {
    height: 3vh;
    margin-left: 1.5rem;
  }
  
  .ribbon {
    width: 100%;
    height: 5.5rem;
    top: -0.5rem;
    background-color: #04FDCF;
    position: absolute;
    left: -1rem;
    -webkit-box-shadow: 0px 15px 11px -6px #7a7a7d;
    box-shadow: 0px 15px 11px -6px #7a7a7d;
  }
  
  .ribbon::before {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    left: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  
  .ribbon::after {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    right: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  
  #buttons {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    
  }
  
  .exit {
    width: 11rem;
    height: 3rem;
    font-family: "Rubik", sans-serif;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #7e7f86;
    border: 0;
    background-color: #fff;
    border-radius: 2rem;
    cursor: pointer;
  }
  
  .exit:hover {
    border: 0.1rem solid #5c5be5;
  }
  
  .continue {
    width: 11rem;
    height: 3rem;
    font-family: "Rubik", sans-serif;
    font-size: 1.3rem;
    color: #fff;
    text-transform: uppercase;
    background-color: #5c5be5;
    border: 0;
    border-bottom: 0.2rem solid #3838b8;
    border-radius: 2rem;
    cursor: pointer;
  }
  
  .continue:active {
    border-bottom: 0;
  }
  
 