.navbar {
    height: 10vh;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    
    background-color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .container {
    text-align: center;
    margin: 20px;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    max-width: 100vw;
   
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateY(-2%);
}
  
.landingpage .nav-icons {
    display: flex;
    margin-right: 2%;
    align-items: center; /* Ensure buttons are vertically aligned */
    gap: 5%; /* Adds space between buttons */
  }
.home-layout2 {
    display: flex;
    min-height: 100vh;
    max-width: 100vw;
    flex-direction: column;
    background-color: black;
    color: #cccccc;
    font-family: sans-serif;
    overflow-x: hidden;
    font-weight: bold;
    
}
.button-6{
    border: none;
    background-color: #413f3f;
    color: #fafbfc;
    border-radius: 5px;
    outline: none
}
.button-7{
    border: none;
    background-color: #0960b1;
    color: #fafbfc;
    border-radius: 5px;
    outline: none
}

.site-footer {
  background-color: #1e1e1e; /* Light grey background */
  text-align: center;
  padding: 0 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #111111;
}

.footer-container {
  
  max-width: 1140px;
  margin: 0 auto;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  display: flex;
  align-items: center; 
}

.github-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff; /* White text */
  margin-left: 15px; /* Spacing between text and link */
}

.github-link img {
  margin-top: 0;
  filter: brightness(0) invert(1); /* Makes the SVG white */
  width: 20px;
  margin-right: 5px;
}

.site-footer .footer-container p {
  color: #a19f9f; /* This makes the selection more specific */
  margin: auto;
  margin-left: 0;
}