
.submit-btn {
  width: 90%;
  margin-top: auto;
  padding: 10px 20px; 
  background-color: #cccccc;
  color: #3c3c3c; 
  border: none; /* Remove default border */
  border-radius: 5px; /* Slightly rounded corners for a modern look */
  font-size: 16px; /* Readable text size */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  display: block; /* Ensure it's a block element */
  margin: 20px auto; /* Center the button with automatic horizontal margins */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.submit-btn:hover {
  background-color: #2D3250; /* Darker shade of blue on hover for interaction feedback */
}

.submit-btn:disabled {
  color:#999db4;
  background-color: #3c3c3c; /* Gray out the button when disabled */
  cursor: not-allowed; /* Change cursor to indicate it's not allowed to click */
}