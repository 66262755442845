
.home-layout {
    display: flex;
    min-height: 95vh;
    max-width: 100vw;
    flex-direction: column;
    background-color: black;
    color: #cccccc;
    font-family: sans-serif;
    overflow-x: hidden;
    font-weight: bold;
    
}



.navbar {
    height: 10vh;
    display: flex;
    z-index: 2000;
    justify-content: space-between;
    padding: 10px;
    background-color: #111111;
    font-family: monospace;
}

.nav-icons {
    display: flex;
    max-width: 50vw;
    min-height: 70%;
    max-height: 100%;
}

.icon-button {
    margin-left: 10px;
    cursor: pointer;
}

.content {
    padding: 20px;
}


.container {
    text-align: center;
    margin: 20px;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    max-width: 100vw;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateY(-2%);
}
.box {
    display: flex; 
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    border-radius: 1%;
    
}
.gamebox {
    margin: 2%;
    box-shadow: 0 4px 8px rgba(224, 217, 217, 0.1);
    border-radius: 1%;
    width: 100%;
    
    justify-content: space-around;
    display: flex; 
    background-color: #111111;
    border: 1.55px solid #618386;
}



.grid {
    display: flex;
    justify-content: space-around; /* Keep space distribution */
    gap: 2%; /* Add space between cards */
    position: relative;
    padding: 1vh;
    background-color: #111111;
    
}

.team {
    
    background-color: #1e1e1e;
   
    flex: 1;
    text-align: center;
    margin: 1vh; /* Slight margin for separation */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for card effect */
    border-radius: 10px; 
    overflow: hidden;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}


.team img {
    max-height: 24vh; /* Smaller maximum height */
    max-width: 90%; /* Maximum width to be less than container width */
    object-fit: cover; 
    margin: 5% auto; 
}

.team-name {
    
    font-size: 100%;
    
}
.team-content {
    color: #618386;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align items to start and end */
    height: 100%; /* Full height of the parent */
}



.team:hover {
    transform: translateY(-5px); /* Slight raise effect on hover */
}

.team-selected {
    animation: changeBackground 0.5s forwards; /* Animation name, duration, fill mode */
}

@keyframes changeBackground {
    from {background-color: transparent;}
    to {background-color: #04FDCF;}
    from {color: transparent;}
    to {color: black} /* Example selected color, adjust as needed */
}



.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #333; /* Change as per your design */
    color: #999; /* Change as per your design */
    text-align: center;
    padding: 10px 0;
    height: 7vh;
  }
  .banner-ad-placeholder {
    width: 468px; /* Full width */
    height: 60px;
    margin-top: 0px;
    padding-bottom: 0%; /* Ratio for maintaining aspect ratio close to 468x60 */
    background: rgb(82, 71, 71);
    
    color: white; /* For visibility of any placeholder text or content */
    text-align: center; /* Centering the content */
}

.banner-ad-placeholder:before {
    content: '';
    display: block;
   /* Height/Width ratio to maintain aspect ratio */
}


/* Responsive styling for smaller screens */
@media (max-width: 600px) {
    .grid {
        grid-template-columns: 1fr;
    }
    .nav-icons {
        display: flex;
        max-width: 47vw;
        min-height: 70%;
        max-height: 100%;
        
    }
    .logo img {
        max-height: 50px; /* Adjust this value to fit your design */
        max-width: 50vw;
        width: auto; /* This maintains the aspect ratio of the image */
    }
    .banner-ad-placeholder {
        width: 400px; /* Full width */
        height: 51.2px; /* Adjust the percentage to maintain a better aspect ratio on smaller screens */
    }
}
