.sports {
    
    flex-wrap: wrap;
    justify-content: center;
    
    
  }
  
  .sport-icon {
    width: 100px;
    height: 100px;
    background-color: #ddd; /* Placeholder, replace with actual icons */
    border-radius: 50%;
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    transform: translateY(20px);
  }

  .expandedText {
    width: 60vw;
    margin-top: 20px;
    padding: 10px;
    background-color: #1a1a1a; /* Light grey background */
    border-radius: 4px;
  }
  
  .sports button {
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #413f3f;
    color: #fafbfc;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    animation: fadeInUp 2s ease-out;
    width: 30vw;
  }
  
  .sports button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .sport-icon.animate {
    opacity: 1;
    transform: translateY(0);
  }