.navbar {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  
  background-color: #111111;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.nav-icons {
  display: flex;
  margin-right: 2%;
  align-items: center; /* Ensure buttons are vertically aligned */
  gap: 5%; /* Adds space between buttons */
}
.logo img {
  max-height: 50px; /* Adjust this value to fit your design */
  max-width: 48vw;
  width: auto; /* This maintains the aspect ratio of the image */
}
.button-56 {
  background-color: #696868;
  border: none;
  border-radius: 20%; /* Makes the button circular */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-flex; /* Use inline-flex to prevent stretching */
  justify-content: center;
  align-items: center; /* Center the icon vertically and horizontally */
  width: 60px; /* Fixed width */
  height: 60px; /* Fixed height, making the button square or circular */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #618386;
}

.button-56:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.button-56:active {
  background-image: linear-gradient(145deg, #048a81, #2e8b57);
  transform: translateY(0);
}

.button-56 img {
  width: 50%; /* Adjust icon size relative to button */
  height: auto; /* Maintain aspect ratio */
}

@media (min-width: 768px) {
  /* Adjustments for larger screens can be made here, if necessary */
}

@media (max-width: 600px) {
  .logo img {
    max-height: 50px;
    max-width: 50vw;
    width: auto;
  }
}