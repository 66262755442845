.text-box {
    background-color: #111111;
    z-index: 0;

    /* Enhanced box shadow for a deeper 3D effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);

    /* Slightly increased border-radius for a smoother edge */
    border-radius: 54%;
    border-top-left-radius: 10%;

    padding: 1%;
    width: 100%; /* Keeps .text-box responsive to the width of .container */

    /* Updated border for more depth and attention */
    border: 2px solid #618386;
    transform: scale(1.23) translateY(41%);
    /* Optional: Gradient or textured background for more depth */
    background-image: linear-gradient(to right, #111111, #000000);
}

.text-box:hover {
    /* Slightly scale up and lift the box on hover for a 3D pop effect */
    

    /* Optional: Change the box-shadow on hover for more depth */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 8px 25px rgba(0, 0, 0, 0.22);
}

.text-content h2, .text-content p {
    margin: 0;
    opacity: 0;
    animation: fadeInUp 1s ease-out forwards;
}

.text-content h2 {
    animation-delay: 0.5s;
}

.text-content .number {
    animation-delay: 0.8s;
}

.text-content .day {
    animation-delay: 1.1s;
}
.name {
    text-transform: uppercase;
}

@keyframes slideIn {
    from {
        transform: translateY(20px) scale(0.98);
        opacity: 0;
    }
    to {
        transform: translateY(0) scale(0.98);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
.stats-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Fill the container */
    
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  
  .stat-label {
    font-weight: bold;
    color: #618386;
    
  }
  
  .stat-value {
    color: #618386;
  }
  
  .divider {
    border: none;
    height: 1px;
    background: aliceblue;
    margin: 10px 0;
  }