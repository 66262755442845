.hero {
    background: linear-gradient(135deg, black, rgb(0, 0, 0));
    color: white;
    text-align: center;
    padding: 20px ;
   
  }
  
  .hero h1 {
    font-weight: 700;
    margin-bottom: 20px;
    animation: fadeInDown 2s ease-out;
  }
  
  .hero button {
    font-family: "JetBrains Mono",monospace;
    width: 100%;
    display: block; 
    margin: 0 auto;
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #0960b1;
    color: #fafbfc;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    animation: fadeInUp 2s ease-out;
    margin-top: 5%;
  }
  .hero .play-now.desktop {
    display: none;
  }
  
  .hero button:hover {
    background-color: #009FFF;
    color: #ffffff;
  }
  .hero .herocontainer {
    display: block; 
    text-align: center; 
  }
  
  .hero .screenshot {
    margin-top: 20px;
    height: 75vh;
    max-width: 600px;
    border-radius: 100px;
    animation: fadeIn 2s ease-out forwards;
    opacity: 0;
  }
  .hero .headimage {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    height: auto;
    
    animation: fadeIn 2s ease-out forwards;
    opacity: 0;
  }
  .hero p {
    margin: 5%;
    font-size: 1.1rem;
    color: #666; /* Lighter text color */
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (min-width: 768px) { /* Adjust this value based on your design's breakpoint */
    .hero .herocontainer {
      display: flex; /* Changes layout to row for wider screens */
      justify-content: center; /* Centers items horizontally in the container */
      align-items: center; /* Aligns items vertically */
      flex-wrap: wrap; /* Allows items to wrap onto the next line if needed */
      gap: 20px; /* Adds space between items */
    }
    .hero .play-now.mobile {
      display: none;
    }
    
    .hero .play-now.desktop {
      display: block; /* Or inline-block, depending on your layout */
      width: 97%;
      height: 5vw;
      margin: 20px auto; /* Adjust as needed */
      /* Positioning styles as previously described */
    }
  
    .hero .headimage, .hero .screenshot {
      margin-top: 0; /* Adjusts or removes margin-top for the flex layout */
      width: auto; /* Adjusts width to maintain aspect ratio without stretching */
      max-width: 600px; /* Ensures the images do not exceed their container's width */
    }
  
    .hero button {
      margin: 0; /* Adjust button margin in flex layout */
    }
  }
  